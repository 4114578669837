import React, { useState } from "react";
import { useLocation } from "@reach/router";
import { Stack, Text } from "basis";

import { logger } from "../../../core";
import { useCustomerAuth } from "../../../core/auth";
import { isPurchaseDisabled } from "../../../core/config";

import {
  Container,
  ErrorMessage,
  Flex,
  ERROR_CODES,
  Link,
  LoadingMessage,
  Profile,
} from "../../../components";
import {
  completePayment,
  AccountErrors,
  PaymentMethod,
  CompletePayment,
  ConfirmPayment,
  ROUTES,
  PointSelection,
  Scene,
  PointsPurchaseHeader,
  MerchantSettingsError,
} from "../../../layout/wallet";
import { useCustomerOrder } from "../../../layout/checkout";
import { retry } from "../../../utils/retry";
import { useMerchantSettings, useThreatMetrix } from "../../../hooks";

const Checkout = () => {
  const location = useLocation();
  const tm = useThreatMetrix();

  const [selectedAccount, setSelectedAccount] = useState();
  const [selectedPoints, setSelectedPoints] = useState(0);
  const [completingPayment, setCompletingPayment] = useState(false);
  const [isValidForm, setIsValidForm] = useState(true);
  const { logout } = useCustomerAuth();

  const { loading, error, order, setErrorMessage, params } = useCustomerOrder();
  const [
    loadingMerchantSettings,
    errorMerchantSettings,
    merchantSettings,
  ] = useMerchantSettings(order?.merchantId);

  const handlePaymentClick = async () => {
    if (loading || completingPayment) {
      return;
    }

    setCompletingPayment(true);

    try {
      const { url } = await retry({
        fn: () =>
          completePayment(
            params.transactionId,
            "",
            selectedAccount,
            selectedPoints,
            tm.getSessionId()
          ),
        onRetryBegin: (numAttempt) =>
          numAttempt > 0 &&
          logger.info(`handlePaymentClick(): retry #${numAttempt}`, {
            params,
            paymentResponse: {
              selectedAccount,
              selectedPoints,
            },
          }),
        attempts: 3,
        sleepMs: 200,
        sleepMsDelay: [800, 1200, 1400],
      });

      window.location.replace(url);
    } catch (err) {
      logger.error("handlePaymentClick(): failed", { params, err });

      setErrorMessage(
        `We could not update your payment request. Ref # ${params.transactionId}`
      );
    }
  };

  const handleManualPaymentClick = async () => {
    if (loading || completingPayment) {
      return;
    }

    setCompletingPayment(true);
    window.location.replace(`${ROUTES.MANUAL_PAYMENT}${location.search}`);
  };

  const handleAccountChange = (newAccount) => {
    setSelectedPoints(minPoints);
    setSelectedAccount(newAccount);
  };

  const validatePoints = (val) => {
    let value = Number(val);
    let message = null;
    //for form to be disabled
    if (isNaN(val)) {
      message = `Invalid number`;
    } else if (Number(value) < minPoints) {
      message = `Minimum ${minPoints} is allowed.`;
    } else if (Number(value) > maxPoints) {
      message = `Maximum ${maxPoints} is allowed.`;
    } else if (val.toString().includes(".")) {
      message = `Decimal is not allowed`;
    }
    message !== null ? setIsValidForm(false) : setIsValidForm(true);
    return message;
  };

  const handlePointsChange = (val) => {
    let err = validatePoints(val);
    if (err === null) {
      setSelectedPoints(val);
    }
    return err === null;
  };

  // TODO: extract out to a component
  const availablePoints = selectedAccount?.points || 0;
  const burnRate = order?.rewards?.burnRate || 0;
  const minPoints = order?.rewards?.minimumPoints || 0;
  const orderPoints = Math.round(order?.amount * burnRate);
  const maxPoints = Math.min(orderPoints, availablePoints);

  const pointsAsAmount = selectedPoints > 0 ? selectedPoints / burnRate : 0;
  const remainingAmount = Math.max(order?.amount - pointsAsAmount, 0);

  const allowPointSelection = availablePoints > 0 && minPoints <= maxPoints;
  const allowPurchase =
    minPoints === 0 ||
    ((order?.accountErrors?.length || 0) === 0 &&
      selectedAccount?.error?.code === "");

  const showLoading = (loading || loadingMerchantSettings) && !error;

  if (showLoading) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <LoadingMessage message="Getting transaction details while you wait .." />
      </Scene>
    );
  }

  if (isPurchaseDisabled) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <ErrorMessage type={ERROR_CODES.PURCHASE_DISABLED} />
      </Scene>
    );
  }

  if (error?.message) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <ErrorMessage
          type={error?.message}
          additionalParams={{
            minimumAmount: order?.minimumAmount,
            currency: order?.currency,
            urlCancel: order?.urlCancel,
            transactionId: order?.transactionId,
          }}
        />
      </Scene>
    );
  }

  if (errorMerchantSettings) {
    return (
      <Scene
        theme={merchantSettings?.theme}
        merchantLogoURL={merchantSettings?.urlLogo}
      >
        <Container margin="4 0" padding="2" maxWidth={1100}>
          <MerchantSettingsError />
        </Container>
      </Scene>
    );
  }

  return (
    <Scene
      theme={merchantSettings?.theme}
      merchantLogoURL={merchantSettings?.urlLogo}
    >
      <Container margin="4 0" padding="2" maxWidth={1100}>
        <Stack gap="6">
          <Flex>
            <Link href={order?.urlCancel} newTab={false} blue>
              &lt; Back to cart
            </Link>

            <Link href="#" onClick={logout} newTab={false}>
              Log out
            </Link>
          </Flex>

          <Text align="left" textStyle="heading4">
            Complete your order
          </Text>
        </Stack>
      </Container>

      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="4 6" maxWidth={600}>
          <PointsPurchaseHeader amount={order?.amount} burnRate={burnRate} />
        </Container>
      </Container>

      <Container bg="white" margin="4 0" maxWidth={1100} shadow>
        <Container bg="white" margin="4 0" padding="4 6" maxWidth={600}>
          <Profile firstName={order?.givenName} />

          <PaymentMethod
            disabled={completingPayment}
            accounts={order?.accounts}
            onChange={handleAccountChange}
          />

          <Container margin="4 0 0">
            <AccountErrors
              minPoints={minPoints}
              errors={order?.accountErrors}
              selectedAccount={selectedAccount}
            />
          </Container>

          {allowPointSelection && (
            <PointSelection
              disabled={completingPayment}
              min={minPoints}
              max={maxPoints}
              value={selectedPoints}
              remainingAmount={remainingAmount}
              onChange={handlePointsChange}
              validate={validatePoints}
            />
          )}

          {allowPurchase && (
            <>
              <ConfirmPayment
                amount={order?.amount}
                selectedAccount={selectedAccount}
                selectedPoints={selectedPoints}
                remainingAmount={remainingAmount}
              />

              <CompletePayment
                disabled={!isValidForm}
                isAccountSelected={Boolean(selectedAccount?.isAvailable)}
                isCompletingPayment={completingPayment}
                allowWalletPayment={order?.accounts?.length > 0}
                onPaymentClick={handlePaymentClick}
                onManualPaymentClick={handleManualPaymentClick}
                country={order?.country}
                urlTerms={merchantSettings?.urlTerms}
              />
            </>
          )}
        </Container>
      </Container>
    </Scene>
  );
};

export default Checkout;
